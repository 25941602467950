import React from 'react'
import App from 'App'
import styled from 'styled-components'
import { COLOR_CONSTANTS, colors, radius } from 'theme'
import { SITE_LINK_PUBLISHING, CONTAINER_MAX_WIDTH } from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import Footer from 'components/Footer'
import { Flex, Box, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H3 } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import imageHeader from 'static/images/integrations/tiktok/header_picture.webp'
import imageToolManage from 'static/images/integrations/tiktok/tools_to_manage.webp'
import imageText1 from 'static/images/integrations/tiktok/image_text_1.webp'
import imageText2 from 'static/images/integrations/tiktok/image_text_2.webp'
import imageText3 from 'static/images/integrations/tiktok/image_text_3.webp'
import imageText4 from 'static/images/integrations/tiktok/image_text_4.webp'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import PageHeader from './components/PageHeader'
import ToolManageSection from './components/ToolManageSection'
import TextImageSection from './components/TextImageSection'
import CoreFeaturesSection from './components/CoreFeaturesSection'
import FaqSection from './components/FaqSection'
import GetStartedSection from '../GetStartedSection'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const NETWORK = 'TikTok'

const TEXT_IMAGES = [
  {
    header: {
      title: `Simplify ${NETWORK} Content Development & Profile Posting`,
      matches: [NETWORK],
    },
    textFirst: false,
    image: imageText1,
    items: [
      {
        title: 'Easily Plan, Publish and Boost Posts',
        description: `Draft posts for one or many ${NETWORK} Profiles — upload videos, attach images from a Media Library, apply targeting and add tags.`,
      },
      {
        title: 'Manage the Content Calendar',
        description: `Easily review and manage ${NETWORK} content from a central publishing calendar—quickly filter by tag, profile and date.`,
      },
      {
        title: 'Seamlessly With Approval Workflow',
        description:
          'Vista’s built-in approval workflow is perfect for legal, compliance, agency collaboration or peer review.',
      },
    ],
    linkText: 'MORE ON PUBLISHING',
    link: SITE_LINK_PUBLISHING,
  },
  {
    header: {
      title: `Schedule ${NETWORK} Videos With Trending Audio`,
      matches: ['Trending Audio'],
    },
    textFirst: false,
    headerMaxWidth: '100%',
    image: imageText3,
    items: [
      {
        title: 'Pick the best trending audio',
        description: `Choose your audio from a list of thoughtfully curated and maintained trending sounds.`,
      },
      {
        title: 'Upload your own sounds',
        description: `Easily upload sounds from ${NETWORK} or your own videos or sound files.`,
      },
      {
        title: 'Add audio options',
        description: 'Fully replace the audio in your video or add it as a background sound.',
      },
    ],
  },
  {
    header: {
      title: `Gather Strategic Insights with ${NETWORK} analytics`,
      matches: [NETWORK],
    },
    textFirst: true,
    image: imageText2,
    items: [
      {
        title: `Visualize ${NETWORK} Profile Data`,
        description:
          'Understand likes, shares, comments and views behavior with data visualizations and dynamic charts.',
      },
      {
        title: 'Identify Your Best Content',
        description: `Dig into ${NETWORK} Insights data and identify best performing content (engagement and viewing).`,
      },
      {
        title: 'Customized and Export Reports',
        description: 'Export insightful reports or customize and build your own to deliver to your stakeholders.',
      },
    ],
  },
]

const IntegrationsSocial = () => {
  return (
    <App fullHeader>
      <SEO
        title={`${NETWORK} management tools for business`}
        description={`Manage ${NETWORK} profiles with features such as direct image and video publishing and analytics. Vista Social is the world's first, fully approved by TikTok, official TikTok integration. No apps to install, no reminders to manage.`}
        path="/integrations/tiktok/"
      />

      <PageHeader
        network={NETWORK}
        headerText={`${NETWORK} management tools for business`}
        description={`Manage ${NETWORK} profiles with features such as direct image and video publishing and analytics. Vista Social is the world's first, fully approved by TikTok, official TikTok integration. No apps to install, no reminders to manage.`}
        image={imageHeader}
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex my="xxl" flexDirection="column" alignItems="center" width="100%">
            <Flex zIndex="1" alignItems="center" flexDirection="column" mx="auto">
              <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
                Plan Your {NETWORK} Images and Videos By Dragging And Dropping With The{' '}
                <Text as="span" color="primary" fontSize="3xl" fontWeight="bold">
                  Visual {NETWORK} Planner
                </Text>
              </H3>
            </Flex>
            <Flex
              mt="xl"
              justifyContent="center"
              flexDirection={{ mobile: 'column', desktop: 'row' }}
              alignItems="center"
              width="100%"
            >
              <Flex
                minWidth={{ mobile: '100%', tablet: '445px' }}
                width="100%"
                alignItems="center"
                mx={{ mobile: 'auto', tablet: 0 }}
                flexDirection="column"
                order={{ mobile: 1, desktop: 2 }}
              >
                <Flex width="100%" justifyContent="center" alignItems="space-between" flexDirection="column">
                  <Grid gridGap={pxToRem(36)} gridTemplateColumns="repeat(1, 1fr)">
                    <Flex alignItems={{ mobile: 'center', tablet: 'unset' }}>
                      <Box
                        mt={{ mobile: 0, tablet: '11px' }}
                        minWidth="8px"
                        width="8px"
                        height="8px"
                        borderRadius={radius.pill}
                        bg={colors.primary}
                      />
                      <Text color={COLOR_CONSTANTS.DENIM} fontSize="l" ml="s" textAlign="left">
                        Arrange your upcoming scheduled images and videos with a drag-and-drop to find the perfect look
                        for your feed.
                      </Text>
                    </Flex>
                    <Flex alignItems={{ mobile: 'center', tablet: 'unset' }}>
                      <Box
                        mt={{ mobile: 0, tablet: '11px' }}
                        minWidth="8px"
                        width="8px"
                        height="8px"
                        borderRadius={radius.pill}
                        bg={colors.primary}
                      />
                      <Text color={COLOR_CONSTANTS.DENIM} fontSize="l" ml="s" textAlign="left">
                        Easily add, remove or change scheduled images and videos right from the planner.
                      </Text>
                    </Flex>
                    <Flex alignItems={{ mobile: 'center', tablet: 'unset' }}>
                      <Box
                        mt={{ mobile: 0, tablet: '11px' }}
                        minWidth="8px"
                        width="8px"
                        height="8px"
                        borderRadius={radius.pill}
                        bg={colors.primary}
                      />
                      <Text color={COLOR_CONSTANTS.DENIM} fontSize="l" ml="s" textAlign="left">
                        Source videos from Canva, stock images and videos and user-generated content.
                      </Text>
                    </Flex>
                    <Flex alignItems={{ mobile: 'center', tablet: 'unset' }}>
                      <Box
                        mt={{ mobile: 0, tablet: '11px' }}
                        minWidth="8px"
                        width="8px"
                        height="8px"
                        borderRadius={radius.pill}
                        bg={colors.primary}
                      />
                      <Text color={COLOR_CONSTANTS.DENIM} fontSize="l" ml="s" textAlign="left">
                        Customize thumbnails and apply trending sounds to your videos.
                      </Text>
                    </Flex>
                    <Flex alignItems={{ mobile: 'center', tablet: 'unset' }}>
                      <Box
                        mt={{ mobile: 0, tablet: '11px' }}
                        minWidth="8px"
                        width="8px"
                        height="8px"
                        borderRadius={radius.pill}
                        bg={colors.primary}
                      />
                      <Text color={COLOR_CONSTANTS.DENIM} fontSize="l" ml="s" textAlign="left">
                        Easily format videos with built-in video editor.
                      </Text>
                    </Flex>
                  </Grid>
                </Flex>
              </Flex>
              <Flex
                maxWidth="594px"
                minWidth={{ mobile: '100%', desktop: '516px', desktopWide: '594px' }}
                ml={{ mobile: 'auto', desktop: 0 }}
                mr={{ mobile: 'auto', desktop: 'l' }}
                mt={{ mobile: 'l', desktop: 0 }}
                order={{ mobile: 2, desktop: 1 }}
              >
                <LocalImage
                  src={imageText4}
                  width="100%"
                  maxWidth="594px"
                  mx="auto"
                  alt={`Plan Your ${NETWORK} Posts By Dragging And Dropping With The Visual ${NETWORK} Planner`}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex mt="s" pt="s" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
            <G2FeaturesComponent />
          </Flex>
        </Container>
      </Wrapper>

      <ToolManageSection
        network={NETWORK}
        headerText={`Tools to Manage Multiple ${NETWORK} Profiles`}
        description="Easily plan and publish TikTok videos and images. Keep your content planning organized, automate your time-consuming image and video scheduling, and add checkpoints to ensure your images and videos are ready to publish."
        image={imageToolManage}
        tools={[
          {
            title: `Create Engaging ${NETWORK} Content`,
            description: `Plan, create and publish engaging posts with a centralized content calendar, intuitive workflows and creative tools for ${NETWORK} publishing.`,
          },
          {
            title: 'Manage Conversations & Build Relationships',
            description:
              'Never miss a comment, wall post, Review or Messenger conversation with a unified inbox and powerful social CRM tools for engagement.',
          },
          {
            title: `Benchmark Your ${NETWORK} Performance`,
            description: `Gather paid and organic insights for one or many ${NETWORK} Profiles to measure fan growth, analyze engagement and track post performance.`,
          },
        ]}
      />

      <TextImageSection textImages={TEXT_IMAGES} />

      <CoreFeaturesSection headerTitle={`Take your ${NETWORK} Marketing to the Next Level`} matches={[NETWORK]} />

      <FaqSection />

      <GetStartedSection />

      <Footer />
    </App>
  )
}

export default IntegrationsSocial
